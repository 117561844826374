import {
  ArrowDownTrayIcon,
  ArrowRightEndOnRectangleIcon,
  GlobeEuropeAfricaIcon,
  HeartIcon,
  MapPinIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';

export default function PricingFeatures() {
  const { t } = useTranslation('home');

  return (
    <div className="mb-10 px-0 lg:px-8 text-on-surface">
      <div className="relative flex flex-col-reverse">
        <div className="absolute left-10 h-full">
          <div className="absolute -top-10 z-10 mb-6 -translate-x-1/2 whitespace-nowrap ">
            <div className={`flex h-fit w-fit items-center justify-center rounded-full bg-green-500/10 p-2`}>
              <div className={`text-normal flex items-center justify-center rounded-full bg-green-300 px-4 py-1`}>
                <span className="font-semibold uppercase text-green-900">START</span>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 top-0 w-[3px] bg-gradient-to-b from-green-500/30 to-green-500/40"></div>
          <div className="absolute top-14 h-[9px] w-[9px] -translate-x-[3px] -translate-y-1/2 rounded-full bg-gray-100 dark:bg-gray-900 ring-4 ring-gray-100 dark:ring-gray-900 ring-offset-2 ring-offset-black dark:ring-offset-white"></div>
        </div>
        <div className="flex flex-1 flex-col justify-start pl-20 pt-10 text-left ">
          <h3 className="mb-4 mt-0 text-2xl">{t('how.setup_title')}</h3>
          <p className="text-md max-w-lg text-secondary">{t('how.setup_description')}</p>

          <div className="ml-4 ">
            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
              <ArrowDownTrayIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.setup_section_1_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.setup_section_1_description')}</p>

            <div className="flex items-center flex-wrap mt-4">
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://play.google.com/store/apps/details?id=com.bringmos.bringmosclient"
              >
                <img height="48px" className="h-full object-contain" src="/images/playstore.png" loading="lazy" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://apps.apple.com/us/app/bringmos/id1497420702?l=de&ls=1"
              >
                <img height="48px" className="h-full object-contain" src="/images/appstore.svg" loading="lazy" />
              </a>
            </div>

            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
              <GlobeEuropeAfricaIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.setup_section_2_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.setup_section_2_description')}</p>
          </div>
        </div>

        <div
          className="relative left-4 top-4 box-border overflow-hidden lg:absolute lg:left-1/2 lg:-top-20 lg:h-[700px] lg:w-full"
          aria-hidden="true"
        >
          <figure className="phonegraphic relative left-16 top-20 xl:left-40" aria-hidden="true">
            <div className="h-full overflow-hidden bg-white p-0">
              <div className="pointer-events-none relative overflow-hidden">
                <div className="block dark:hidden">
                  <Image className="relative" width={450} height={1000} src="/mockups/android_home.jpg" alt="home view" />
                </div>
                <div className="hidden dark:block">
                  <Image
                    className="relative"
                    width={450}
                    height={1000}
                    src="/mockups/android_home_dark.png"
                    alt="home view"
                  />
                </div>
              </div>
            </div>
          </figure>
        </div>
      </div>

      <div className="relative flex flex-col-reverse">
        <div className="absolute left-10 h-full">
          <div className="absolute bottom-0 top-0 w-[3px] bg-gradient-to-b from-green-500/40 to-green-500/40"></div>
          <div className="absolute top-20 h-[9px] w-[9px] -translate-x-[3px] -translate-y-1/2 rounded-full bg-gray-100 dark:bg-gray-900 ring-4 ring-gray-100 dark:ring-gray-900 ring-offset-2 ring-offset-black dark:ring-offset-white"></div>
        </div>

        <div className="flex flex-1 flex-col justify-start pl-20 pt-16 text-left">
          <h3 className="mb-4 mt-0 text-2xl">{t('how.menu_title')}</h3>
          <p className="text-md max-w-lg text-secondary">{t('how.menu_description')}</p>

          <div className="ml-4">
            <div className="flex flex-row space-x-4">
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
                <Image height={32} width={32} alt="fish" src="/placeholders/fish.svg" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-[2px]">
                <Image height={32} width={32} alt="burger" src="/placeholders/burger.svg" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-[2px] opacity-70">
                <Image height={32} width={32} alt="pasta" src="/placeholders/pasta.svg" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-[2px] opacity-50">
                <Image height={32} width={32} alt="asian" src="/placeholders/asian.svg" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-[2px] opacity-20">
                <Image height={32} width={32} alt="pizza" src="/placeholders/pizza.svg" />
              </div>

              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-[2px] opacity-10">
                <Image height={32} width={32} alt="southtyrolean" src="/placeholders/southtyrol.svg" />
              </div>
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.menu_section_1_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.menu_section_1_description')}</p>

            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-2">
              <StarIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.menu_section_2_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.menu_section_2_description')}</p>

            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-2">
              <HeartIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.menu_section_3_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.menu_section_3_description')}</p>
          </div>
        </div>

        <div
          className="relative left-4 top-4 box-border overflow-hidden rounded-lg border border-outline-variant shadow-mockup lg:absolute lg:left-2/3 lg:top-28 lg:h-[560px] lg:w-[900px]"
          aria-hidden="true"
        >
          <div className="block dark:hidden -translate-x-1 transform">
            <Image width={1292} height={931} src="/mockups/custom_pizza_desktop.png" alt="custom pizza" />
          </div>
          <div className="hidden dark:block -translate-x-1 transform">
            <Image width={1337} height={931} src="/mockups/custom_pizza_desktop_dark.png" alt="custom pizza" />
          </div>
        </div>
      </div>

      <div className="relative flex flex-col-reverse">
        <div className="absolute left-10 h-full">
          <div className="absolute bottom-0 top-0 w-[3px] bg-gradient-to-b from-green-500/40 to-yellow-500/40"></div>
          <div className="absolute top-20 h-[9px] w-[9px] -translate-x-[3px] -translate-y-1/2 rounded-full bg-white dark:bg-gray-900 ring-4 ring-gray-100 dark:ring-gray-900 ring-offset-2 ring-offset-black dark:ring-offset-white"></div>
        </div>

        <div className="flex flex-1 flex-col justify-start pl-20 pt-16 text-left">
          <h3 className="mb-4 mt-0 text-2xl">{t('how.share_title')}</h3>
          <p className="text-md max-w-lg text-secondary">{t('how.share_description')}</p>

          <div className="mb-4 ml-4">
            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-2">
              <MapPinIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.share_section_1_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.share_section_1_description')}</p>

            <div className="flex flex-row space-x-4">
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
                <Image className="object-center" height={16} width={30} src="/cards/visa.svg" alt="Visa" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
                <Image className=" object-center" height={16} width={30} src="/cards/mastercard.svg" alt="Mastercard" />
              </div>
              <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-1">
                <Image className=" object-center" height={16} width={30} src="/cards/monni.svg" alt="Monni" />
              </div>
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.share_section_2_title')} </h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.share_section_2_description')}</p>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col-reverse">
        <div className="absolute left-10 h-full">
          <div className="absolute bottom-0 top-0 w-[3px] bg-gradient-to-b from-yellow-500/40 to-purple-500/40"></div>
          <div className="absolute top-[358px] -translate-x-1/2 -translate-y-1/2 lg:top-10">
            <div className={`flex h-fit w-fit items-center justify-center rounded-full bg-yellow-500/10 p-2`}>
              <div className={`text-normal flex items-center justify-center rounded-full bg-yellow-300 px-4 py-1`}>
                <span className="font-semibold uppercase text-yellow-900">ORDER</span>
              </div>
            </div>
          </div>
          <div className="absolute top-28 h-[9px] w-[9px] -translate-x-[3px] -translate-y-1/2 rounded-full bg-gray-100 dark:bg-gray-900 ring-4 ring-gray-100 dark:ring-gray-900 ring-offset-2 ring-offset-black dark:ring-offset-white"></div>
        </div>

        <div className="flex flex-1 flex-col justify-start pl-20 text-left">
          <p className="mb-8 ml-4 mt-[30px] text-sm italic text-gray-500">{t('how.send_order')}</p>
          <h3 className="mb-4 mt-4 text-2xl">{t('how.subscribe_title')}</h3>
          <p className="text-md max-w-lg text-secondary">{t('how.subscribe_description')}</p>

          <div className="ml-4">
            <div className="mb-2 mt-10 flex h-10 w-10 flex-row items-center justify-center rounded-lg border border-outline-variant p-2 ">
              <ArrowRightEndOnRectangleIcon className="h-8 w-8 text-tertiary" />
            </div>
            <h4 className="my-2 text-xl font-semibold">{t('how.subscribe_section_1_title')}</h4>
            <p className="max-w-sm text-sm text-secondary">{t('how.subscribe_section_1_description')}</p>
          </div>
        </div>

        <div
          className="relative left-4 top-4 box-border overflow-hidden lg:absolute lg:left-1/2 lg:-top-20 lg:h-[700px] lg:w-full"
          aria-hidden="true"
        >
          <figure className="phonegraphic relative left-16 top-20 xl:left-40" aria-hidden="true">
            <div className="h-full overflow-hidden bg-white p-0">
              <div className="pointer-events-none relative overflow-hidden">
                <div className="block dark:hidden">
                  <Image className="relative" width={450} height={1000} src="/mockups/status.png" alt="status view" />
                </div>
                <div className="hidden dark:block">
                  <Image className="relative" width={450} height={1000} src="/mockups/status_dark.png" alt="status view" />
                </div>
              </div>
            </div>
          </figure>
        </div>
      </div>

      <div className="relative flex flex-col-reverse">
        <div className="absolute left-10 h-full">
          <div className="absolute bottom-0 top-0 w-[3px] bg-gradient-to-b from-purple-500/40 to-teal-500/20"></div>
          <div className="absolute top-24 -translate-x-1/2 -translate-y-1/2">
            <div className={`flex h-fit w-fit items-center justify-center rounded-full bg-purple-500/10 p-2`}>
              <div className={`text-normal flex items-center justify-center rounded-full bg-purple-300 px-4 py-1`}>
                <span className="whitespace-nowrap font-semibold uppercase text-purple-900">enjoy</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-start pl-20 pt-28 text-left"></div>
      </div>
    </div>
  );
}
