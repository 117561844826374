import Image from 'next/image';

const DeviceMock = () => {
  return (
    <div className="pointer-events-none relative top-0 z-20 box-border">
      <div
        className="absolute left-40 top-0 box-border h-[600px] w-[900px] overflow-hidden rounded-lg border border-outline-variant xl:left-64"
        aria-hidden="true"
      >
        <div className="flex flex-row space-x-2 bg-gray-500/5 p-4 px-4">
          <div className="h-2 w-2 rounded-full ring-2 ring-red-500"></div>
          <div className="h-2 w-2 rounded-full ring-2 ring-yellow-500"></div>
          <div className="h-2 w-2 rounded-full ring-2 ring-green-500"></div>
        </div>
        <div className="block dark:hidden">
          <Image
            className="-translate-x-1 transform "
            width={1495}
            height={931}
            src="/mockups/menu_meat.png"
            alt="desktop view"
          />
        </div>
        <div className="hidden dark:block">
          <Image
            className="-translate-x-1 transform "
            width={1495}
            height={931}
            src="/mockups/menu_meat_dark.png"
            alt="desktop view"
          />
        </div>
        {/* <div className="border-l border-white border-opacity-20"></div> */}
      </div>
      <figure className="phonegraphic relative left-16 top-20 xl:left-40" aria-hidden="true">
        <div className="h-full overflow-hidden bg-white p-0">
          <div className="pointer-events-none relative overflow-hidden">
            <div className="block dark:hidden">
              <Image className="relative" width={450} height={1000} src="/mockups/foodmobile.jpg" alt="mobile view" />
            </div>

            <div className="hidden dark:block">
              <Image className="relative" width={450} height={1000} src="/mockups/foodmobile_dark.jpg" alt="mobile view" />
            </div>
          </div>
        </div>
      </figure>
    </div>
  );
};

export const LoginPage = () => {
  return (
    <div className="min-w-92 w-92 absolute left-1/2 top-1/2 mt-20 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
      <Image
        className="-translate-x-1 transform"
        width={1495}
        height={931}
        src="/images/mockups/menu_meat.png"
        alt="desktop view"
      />
    </div>
  );
};

export default DeviceMock;
