
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import Image from 'next/image';
import HomeHeader from '../components/HomeHeader';
import PricingFeatures from '../components/PricingFeatures';

function Home() {
  const { t } = useTranslation('home');

  return (
    <div className="bg-gray-50 dark:bg-gray-900 overflow-x-hidden">
      <Head>
        <title>{t('meta.title')}</title>
      </Head>

      <HomeHeader />

      <Image
        width={191}
        height={256}
        alt="carciofo"
        data-aos="zoom-in-down"
        className="hidden sm:block dark:hidden absolute w-auto h-64 top-4 left-4"
        src="/fruit/carciofo.png"
        loading="lazy"
      />

      <Image
        alt="bowl"
        width={384}
        height={384}
        className="hidden dark:md:block absolute w-auto h-96 -top-8 -left-16"
        src="/fruit/bowl.png"
        loading="lazy"
      />

      <img
        alt="pumpkin"
        data-aos="zoom-in-down"
        className="hidden sm:block dark:hidden h-48 absolute bottom-4 right-4"
        src="/fruit/kurbis.png"
        loading="lazy"
      />

      <img
        alt="melanzane"
        data-aos="zoom-in-down"
        className="hidden sm:block dark:hidden h-48 absolute top-80 right-4"
        src="fruit/melanzane.png"
        loading="lazy"
      />

      <img
        alt="chili"
        data-aos="zoom-in-down"
        className="hidden sm:block dark:hidden h-52 absolute bottom-1/3 left-4"
        src="fruit/chili.png"
        loading="lazy"
      />

      <div className="pt-28 -ml-4 max-w-6xl px-8 lg:mx-auto lg:px-6 pb-48">
        <PricingFeatures />
      </div>
    </div>
  );
}

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  