import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import AddressField from './AddressField';
import DeviceMock from './DeviceMock';

export default function HomeHeader() {
  const { t } = useTranslation('home');
  const router = useRouter();

  function getLocation(pl: any) {
    const url = `/restaurants/${encodeURI(pl.formatted_address)}`;
    router.push(url);
  }

  return (
    <div className="container mx-auto max-w-6xl px-8 pt-28 pb-28">
      <div className="m-auto mx-auto grid h-full w-full grid-cols-1 sm:grid-cols-2">
        <div className="z-10 box-border flex flex-col justify-center">
          <h1 className="text-on-surface sm:text-6-xl mb-8 text-center text-5xl font-semibold sm:text-left lg:text-7xl">
            <span className="">{t('header.title')}</span>
          </h1>
          <p className="text-secondary text-xl mb-8">{t('header.description')}</p>

          <AddressField selectionChanged={getLocation} />

          <p className="pl-5 pt-2 text-[11px] text-gray-600">{t('header.explore_restaurants')}</p>
        </div>
        <div className="sm:min-w-1/2 box-border hidden min-h-full sm:block">
          <DeviceMock />
        </div>
      </div>
    </div>
  );
}
